@media screen and (min-width : 330px) and  (max-width : 1027px) {

    .display-heading-sm {
      font-size: 4em;
      font-weight: 300;
      line-height: 1.2;
    }
    
  };

  @media screen and (min-width : 1px) and  (max-width : 329) {

    .display-heading-xs {
      font-size: 3em;
      font-weight: 300;
      line-height: 1.2;
    }
    
  };