.navbar.navbar-dark .breadcrumb .nav-item.active > .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item.active > .nav-link {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.disabled {
  border: none !important;
}

/* Margins and Spacing */
.text-left {
  text-align: left;
}

.divider-margin {
  margin-top: 2em;
  margin-bottom: 2em;
}

.divider-padding {
  padding-top: 2em;
  padding-bottom: 2em;
}

.no-padding-margin {
  padding: 0px;
  margin: 0px;
}

.footer-padding {
  padding-bottom: 4em;
}

.divider-margin-lg {
  margin-top: 4em;
  margin-bottom: 4em;
}

.divider-margin-sm {
  margin-top: 1em;
  margin-bottom: 1em;
}

.divider-padding {
  padding-top: 3em;
  padding-bottom: 3em;
}

.padding-big {
  margin-top: 3em;
  margin-bottom: 3em;
  margin-right: 3em;
  margin-left: 3em;
}

.heading-margin {
  margin-bottom: 0.5em;
}

.heading-margin-lg {
  margin-bottom: 1.5em;
}

.mt-sm {
  margin-top: 2em;
}

.mb-sm {
  margin-bottom: 2em;
}

.mt-lg {
  margin-top: 6em;
}

.mb-lg {
  margin-bottom: 6em;
}

.center-line {
  margin-left: auto;
  margin-right: auto;
}

.center {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #eeeeee;
}

@media screen and (max-width: 767px) {
  .mt-sm {
    margin-top: 0.5em;
    margin-bottom: 2em;
  }
}

/* Text  */

.font-sz-lg {
  font-size: 3em !important;
  color: rgb(53, 49, 49);
}

.grey-text {
  color: #adadad;
}

.darkgrey-text {
  color: #1b1b1b;
}

.darkgrey-bg {
  background-color: #0f0f0f;
}

.footer-color {
  background-color: rgb(0, 0, 0);
  color: white;
}

.footer-height {
  min-height: 40vh;
}

/* Text styling */
.font-small {
  font-size: -1em;
}

.text-small {
  font-size: 10px;
}

@media (min-width: 581px) {
  /* .heading-main-sm {
    font-size: 6em;
    font-weight: 400;
    line-height: 1.5;
  } */

  .heading-h1-lg {
    font-size: 4em;
    font-weight: 300;
    line-height: 1.2;
  }

  .heading-h2-lg {
    font-size: 300%;
  }

  .heading-h3-lg {
    font-size: 200%;
  }

  .heading-main-lg {
    font-size: 6.5em;
    font-weight: 400;
    line-height: 1.5;
  }
}

.font-light {
  color: rgb(87, 87, 87);
}

.font-lighter {
  color: rgb(194, 194, 194);
}

.heading-tagline {
  font-size: 110%;
}

@media (min-width: 0px) and (max-width: 580px) {
  .heading-h1-sm {
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.2;
  }

  .heading-h2-sm {
    font-size: 240%;
  }

  .heading-h3-sm {
    font-size: 130%;
  }

  .heading-main-sm {
    font-size: 3.5em;
    font-weight: 300;
    line-height: 1.2;
  }
}

@media (min-width: 0px) and (max-width: 580px) {
  .heading-main-sm {
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media (min-width: 581px) and (max-width: 1050px) {
  .heading-main-md {
    font-size: 2em;
    font-weight: 300;
    line-height: 1;
  }
}

@media (min-width: 1050px) {
  .heading-main-lg {
    font-size: 3em;
    font-weight: 300;
    line-height: 1;
  }
}

/* Font Families */

.font-times {
  font-family: "Times New Roman", Times, serif;
}

.font-pala {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

.font-cursive {
  font-family: cursive;
}

.font-treb {
  font-family: Trebuchet MS, sans-serif;
  /* font-stretch: ultra-expanded; */
}

.font-arial {
  font-family: Arial, Helvetica, sans-serif;
}

.font-hel {
  font-family: "ACaslonProBold", Helvetica, Arial, sans-serif;
}

.font-text {
  /* padding-top: 80px; */
  font-family: "ACaslonProBold", Helvetica, Arial, sans-serif;
  font-size: 10px;
  letter-spacing: 2.67px;
}

/* Colors  */

.background-light {
  background-color: rgb(255, 255, 255);
  color: rgb(36, 36, 36);
}

.background-grey {
  background-color: rgb(14, 14, 14);
  color: white;
}

.bg-darkest {
  background-color: rgb(212, 212, 212);
  color: white;
  padding-top: 2em;
  padding-bottom: 2em;
}

.bg-darker {
  background-color: rgb(31, 31, 31);
  color: white;
  padding-top: 2em;
  padding-bottom: 2em;
}

.bg-black {
  background-color: rgb(0, 0, 0);
  color: white;
}

.bg-main {
  /* background-image: url('https://www.locationhomes.co.nz/wp-content/uploads/2019/03/TH2.jpg'); */
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
}

.bg-main-page {
  /* background-image: url(../img/social.png); */
  /* background-position: center; */
  background-repeat: repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 200px;
  background: linear-gradient(rgb(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)),
    url(../img/light.jpg) repeat center center fixed;
  color: white;
}

.header-container {
  position: relative;
  min-height: 50vh;
}

.header-content {
  position: absolute;
  bottom: 8vh;
  /* left: 0; */
}

.dark-red {
  background-color: #ca1e1e;
}

/* Background Image  */

.bg-dark-header {
  /* background-image: url("https://lumion.com/wp-content/uploads/2016/05/01ss-1.jpg"); */
  height: 50vh;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1.5em;
  padding-top: 1em;
}

/* When you mouse over the container, fade in the overlay title */
.container:hover .overlay {
  opacity: 1;
}

/* Hamburger Nav Styling */

.solid-bg-nav {
  background-color: rgb(43, 42, 42) !important;
}

nav {
  padding: 0px !important;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-brand {
  padding-left: 20px;
  max-width: 300px;
}

@media (min-width: 991px) {
  #navbarCollapse2 {
    padding-right: 20px !important;
  }
}

@media (max-width: 991px) {
  #navbarCollapse2 {
    background-color: rgb(43, 42, 42);
    border-radius: 0px 0px 10px 10px;
    opacity: 1;
    margin-top: 70px;
  }

  .nav-item {
    min-width: 60%;
    margin: 5px 0px;
    text-align: center;
  }
}

.dark-nav {
  background-color: black;
}

@media screen and (min-width: 1028px) {
  .display-heading {
    font-size: 5.5em;
    font-weight: 300;
    line-height: 1.2;
  }
}

.size20 {
  max-height: 440px;
  border-radius: 5px;
}

.border5 {
  border-radius: 5px;
}

.hide-overflow {
  overflow: hidden !important;
}

.footer-img {
  height: 24px;
}

.height-150 {
  height: 220px;
}

.react-slideshow-container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
}

.react-slideshow-container .nav {
  display: none;
}

.image-container {
  height: 100vh;
  width: 100%;
}

/* Logo  */

.logo-small-nav {
  height: 100px !important;
  margin-top: 20px;
  margin-right: 50px;
}

.logo-container {
  top: 7px;
  margin-left: 0;
  padding-left: 0;
}

.logo-nav {
  height: 130px;
  position: absolute;
  top: 20px;
  left: 20px;
  margin-left: 11px;
  /* padding-top: 5rem;
  padding-bottom: 10px; */
}

.logo-small {
  height: 30px;
}

.logo-large {
  height: 150px;
}

.logo-padding {
  padding-top: 20px;
  padding-bottom: 10px;
}

.red-color {
  background-color: #ff0000;
}

.red-color-text {
  color: #ff0000;
}

.spacer-top-scroll {
  padding-top: 1em;
}

.nav-logo {
  flex: 1 0 50%;
}

.bg-30 {
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: 40vh;
}

.bg-10 {
  height: 10vh;
}

.bg-white {
  background-color: whitesmoke;
}

.par-heading {
  z-index: 99;
}

@media screen and (max-width: 586px) {
  .par-heading {
    /* position: absolute;
    top: 40vh;
    left: calc(100% / 4);
    transform: translateX(-50%); */
    margin-left: 20px;
    margin-right: auto;
  }
}

@media screen and (min-width: 587px) {
  .par-heading {
    /* position: absolute;
      top: 40vh;
      left: calc(100% / 4);
      transform: translateX(-50%); */
    margin-left: 50px;
    margin-right: auto;
  }
}

@media screen and (min-width: 587px) {
  .header-inner {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 586px) {
  .header-inner {
    display: flex;
    align-items: flex-end;
    padding-bottom: 15vh;
  }
}

/* ------------------------------------------------ */

/* Google Map iframe */

.bg-30 {
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.map {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 80%;
  min-height: 400px;
  width: 100%;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
}
